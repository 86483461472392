import { ModalStatus, Text, useModalState } from "@clipboard-health/ui-react";
import { Button, DialogContent, Stack } from "@mui/material";
import { FullScreenDialog } from "@src/appV2/lib";
import { NfcReaderError } from "@src/appV2/Nfc/utils/nfcReaderError";
import { startNFCScan } from "@src/appV2/Nfc/utils/oldNfcReader";
import { useMemo, useState } from "react";

export function NfcPluginDebugger() {
  const scanner = useMemo(() => startNFCScan(), []);

  const modalState = useModalState(ModalStatus.CLOSED);

  const [readValue, setReadValue] = useState<string>();
  const [readError, setReadError] = useState<string>();
  const [readerState, setReaderState] = useState<"idle" | "reading" | "stopped">("idle");

  const startReading = () => {
    setReaderState("reading");

    scanner
      .readOne(5000)
      .then((readValue) => {
        setReaderState("idle");
        setReadValue(readValue);
        setReadError(undefined);
      })
      .catch((error) => {
        setReaderState("idle");
        setReadValue(undefined);
        if (error instanceof NfcReaderError) {
          setReadError(`Known: ${error.type}`);
          return;
        }

        setReadError(`Unknown: ${error?.message as string}`);
      });
  };

  return (
    <>
      <Button variant="outlined" size="small" onClick={modalState.openModal}>
        Open NFC plugin debugger
      </Button>
      <FullScreenDialog modalState={modalState}>
        <DialogContent>
          <Stack spacing={2}>
            <Text variant="h2">NFC plugin debugger</Text>
            <Text>State: {readerState}</Text>
            {readValue ? <Text color="green">Read Value: ({readValue})</Text> : null}
            {readError ? <Text color="red">Error: {readError}</Text> : null}
            <Text>
              <em>Will timeout after 5 seconds</em>
            </Text>

            <Button
              variant="contained"
              disabled={readerState !== "idle"}
              onClick={() => {
                startReading();
              }}
            >
              Start reader
            </Button>
            <Button
              variant="contained"
              disabled={readerState !== "reading"}
              onClick={() => {
                void scanner.cancel();
                setReaderState("stopped");
                setReadValue(undefined);
                setReadError(undefined);
              }}
            >
              Stop reader
            </Button>
            <Button variant="outlined" color="secondary" onClick={modalState.closeModal}>
              Close
            </Button>
          </Stack>
        </DialogContent>
      </FullScreenDialog>
    </>
  );
}
