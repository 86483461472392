import { Text, Title } from "@clipboard-health/ui-react";
import { Box, Button, Divider, Stack, Table, TableBody, TableCell, TableRow } from "@mui/material";
import { RootPaths } from "@src/appV2/App/paths";
import { type ReactElement, useState } from "react";

import { environmentConfig } from "../environment";
import { AppBarHeader, BackButtonLink, PageWithHeader, useToast } from "../lib";
import { DebugCards } from "./Cards/Cards";
import { DebugErrorPage } from "./DebugErrorPage";
import { NativePlugins } from "./NativePlugins/NativePlugins";
import { NfcPluginDebugger } from "./NativePlugins/NfcPluginDebugger";
import { NfcScannerDebugPage } from "./Pages/NfcScannerDebugPage";

export function DebugPage(): ReactElement {
  const { showErrorToast, showSuccessToast, showInfoToast, showWarningToast } = useToast();

  const TOASTS = {
    error: showErrorToast,
    info: showInfoToast,
    warning: showWarningToast,
    success: showSuccessToast,
  };
  const [shouldThrowError, setShouldThrowError] = useState(false);

  const environmentVariables = [
    { name: "REACT_APP_ENVIRONMENT_NAME", value: environmentConfig.REACT_APP_ENVIRONMENT_NAME },
    { name: "REACT_APP_VERSION_NAME", value: environmentConfig.REACT_APP_VERSION_NAME ?? "" },
    {
      name: "REACT_APP_BITRISE_BUILD_NUMBER",
      value: environmentConfig.REACT_APP_BITRISE_BUILD_NUMBER ?? "???",
    },
    {
      name: "REACT_APP_DATADOG_RUM_SOURCEMAP_VERSION",
      value: environmentConfig.REACT_APP_DATADOG_RUM_SOURCEMAP_VERSION ?? "???",
    },
  ];

  return (
    <PageWithHeader
      appBarHeader={
        <AppBarHeader
          title="Debug Page"
          leftCta={<BackButtonLink defaultBackTo={`${RootPaths.HOME}/account`} />}
        />
      }
      containerVariant="without-margin"
    >
      <Stack spacing={2} sx={{ padding: 2 }}>
        <Box>
          <Title component="h3" sx={{ textAlign: "center" }}>
            Native Plugins
          </Title>
          <NativePlugins />
        </Box>
        <Divider sx={{ my: 2 }} />
        <Box>
          <Title component="h3" sx={{ textAlign: "center" }}>
            Environment Variables
          </Title>
          <Table>
            <TableBody>
              {environmentVariables.map((environmentVariable) => (
                <TableRow key={environmentVariable.name}>
                  <TableCell>
                    {environmentVariable.name}: <b>{environmentVariable.value}</b>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
        <Box>
          <Text variant="h2" color="error">
            Testing error color override. If this is black, then the `cbhAppV1` class is overriding
            headings.
          </Text>
        </Box>
        <Divider sx={{ my: 2 }} />
        <Box>
          <Title component="h3" sx={{ textAlign: "center" }}>
            Toasts
          </Title>
          <Stack spacing={2}>
            {Object.keys(TOASTS).map((toastName) => {
              return (
                <Button
                  key={toastName}
                  variant="contained"
                  onClick={() => {
                    TOASTS[toastName as keyof typeof TOASTS](
                      `You clicked ${toastName}. This is a very long toast, so check it out.`
                    );
                  }}
                >
                  Show {toastName}
                </Button>
              );
            })}
          </Stack>
        </Box>
        <Divider sx={{ my: 2 }} />
        <Box>
          <Title component="h3" sx={{ textAlign: "center" }}>
            Error Logging
          </Title>
          <Stack spacing={2}>
            <Button
              variant="contained"
              color="error"
              onClick={() => {
                throw new Error(`Debugger Error for Frontend Testing.`);
              }}
            >
              Throw an Error
            </Button>
            <Button
              variant="contained"
              color="error"
              onClick={() => {
                setShouldThrowError(true);
              }}
            >
              Render an Error Component
            </Button>
          </Stack>
          <DebugErrorPage shouldThrowError={shouldThrowError} />
        </Box>
        <Divider sx={{ my: 2 }} />
        <Box>
          <Title component="h3" sx={{ textAlign: "center" }}>
            NFC Debuggers
          </Title>
          <Stack spacing={1} mt={1}>
            <NfcPluginDebugger />
            <NfcScannerDebugPage />
          </Stack>
        </Box>
        <Divider sx={{ my: 2 }} />
        <Box>
          <Title component="h3" sx={{ textAlign: "center" }}>
            Route Debugging
          </Title>
          <DebugCards
            cards={[
              {
                id: 1,
                content: <Text>Open Shifts</Text>,
                to: "/home/openShifts",
              },
              {
                id: 2,
                content: <Text>Pull To Refresh Debugger</Text>,
                to: "/home-v2/debug/pull-to-refresh",
              },
            ]}
          />
        </Box>
      </Stack>
    </PageWithHeader>
  );
}
